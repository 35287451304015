import React from "react";

import AccountsFortnite from "@/game-fortnite/Accounts.jsx";
import { PageContainer } from "@/hub-settings/Common.style.jsx";

export default function FortniteSettings() {
  return (
    <PageContainer>
      <AccountsFortnite />
    </PageContainer>
  );
}
