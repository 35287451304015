import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import { GAME_SYMBOL_FORTNITE } from "@/game-fortnite/definition-symbol.mjs";
import type { PlayerCore } from "@/game-fortnite/models/game-events.mjs";
import Static from "@/game-fortnite/utils/static.mjs";
import Accounts from "@/hub-settings/Accounts.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

type Profile = PlayerCore & Partial<{ anonymous: boolean }>;

export default function AccountsFortnite() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  // @ts-ignore Trying to assign the correct types to this causes an error
  const fortnite: { [accountId: string]: Profile } | undefined =
    state.settings.loggedInAccounts.fortnite;
  const profiles = useMemo(
    () =>
      Object.entries(fortnite ?? {}).map(([, profile]: [string, Profile]) => ({
        displayName: profile.anonymous
          ? t("fortnite:match.anonymous", "Anonymous")
          : profile.name,
        key: profile.accountId,
        avatar: Static.getProfileImage(profile.accountId),
      })),
    [fortnite, t],
  );
  return (
    <Accounts
      profiles={profiles}
      gameShort={GAME_SHORT_NAMES[GAME_SYMBOL_FORTNITE]}
      autoDetectText={[
        "fortnite:settings.autoDetectProfile",
        "Hey! I can automatically detect your profile. Just open Fortnite and log into the account you want to connect.",
      ]}
      // TSX warnings, this is a JSX file being used in a TSX file
      renderOtherActionItems={undefined}
      renderOtherItems={undefined}
    />
  );
}
